import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import logo from "../../svg/logo-horizontal-white.svg"
import scrollUp from "../../svg/scroll-up.svg"
import scrollDown from "../../svg/scroll-down.svg"
import styles from "../../styles/home/home-beer.module.scss"

const HomeBeer = ({
  isMobile,
  scrollCounter,
  setScrollCounter,
  reactFullPageRef,
}) => {
  const scrollUpFunction = () => {
    if (scrollCounter > 0) {
      reactFullPageRef.current.scrollCounter--
      setScrollCounter(reactFullPageRef.current.scrollCounter)
    } else {
      window.location.href = "/#hero"
    }
  }

  const scrollDownFunction = () => {
    if (scrollCounter > 2 || (scrollCounter > 4 && isMobile)) {
      reactFullPageRef.current.scrollCounter++
      setScrollCounter(reactFullPageRef.current.scrollCounter)
    } else {
      isMobile
        ? (reactFullPageRef.current.scrollCounter = 4)
        : (reactFullPageRef.current.scrollCounter = 2)
      window.location.href = "/#history"
    }
  }

  return (
    <div className="section">
      <div className={styles.homeBeerContainer}>
        <img className={styles.homeBeerLogo} src={logo} alt="Goose Island" />
        <div
          className={`${styles.homeBeerTitle} ${
            scrollCounter > 0 && styles.homeBeerHiddenTitle
          }`}
        >
          UNA LAGER SERVIDA EN BANDEJA.<br/>
          EN REALIDAD,<br/>
          DEBERÍAS SERVIRLA EN UN VASO.
        </div>
        <div
          className={`${styles.homeBeerBackgroundContainer} ${
            isMobile &&
            (scrollCounter === 2 || scrollCounter === 4) &&
            styles.homeBeerBackgroundMobileContainerWithText
          }`}
        >
          <StaticImage
            src="../../images/09-without-can.jpg"
            height={1080}
            quality={100}
            formats={["AUTO"]}
            alt="Overlay"
            placeholder="none"
            objectFit="cover"
            className={styles.homeBeerBackgroundImage}
          />
        </div>
        <div
          className={`${styles.homeBeerCanContainer} ${
            scrollCounter < 1 && styles.homeBeerHiddenOnTopCanContainer
          } ${
            isMobile &&
            scrollCounter === 2 &&
            styles.homeBeerMobileCanWithTextContainer
          }
          ${
            isMobile
              ? scrollCounter > 2 && styles.homeBeerHiddenOnTheLeftCanContainer
              : scrollCounter > 1 && styles.homeBeerHiddenOnTheRightCanContainer
          }`}
        >
          <StaticImage
            src="../../images/lager.png"
            height={1080}
            quality={100}
            formats={["AUTO"]}
            alt="Can"
            placeholder="none"
            objectFit="contain"
            className={`${styles.homeBeerCanImage} ${styles.homeBeerLagerCanImage}`}
          />
        </div>
        <div
          className={`${styles.homeBeerCanContainer} ${
            isMobile
              ? scrollCounter < 3 && styles.homeBeerHiddenOnTopCanContainer
              : scrollCounter < 2 && styles.homeBeerHiddenOnTopCanContainer
          } ${
            isMobile &&
            scrollCounter === 4 &&
            styles.homeBeerMobileCanWithTextContainer
          }
          ${
            isMobile
              ? scrollCounter > 4 && styles.homeBeerHiddenOnTheRightCanContainer
              : scrollCounter > 2 && styles.homeBeerHiddenOnTheRightCanContainer
          }`}
        >
          <StaticImage
            src="../../images/can.png"
            height={1080}
            quality={100}
            formats={["AUTO"]}
            alt="Can"
            placeholder="none"
            objectFit="contain"
            className={styles.homeBeerCanImage}
          />
        </div>
        <div
          className={`${styles.homeBeerIPAContentContainer} ${
            isMobile
              ? scrollCounter < 2 &&
                styles.homeBeerIPAHiddenOnTheRightContentContainer
              : scrollCounter < 1 &&
                styles.homeBeerIPAHiddenOnTheLeftContentContainer
          }
          ${
            isMobile &&
            scrollCounter === 1 &&
            styles.homeBeerIPAHiddenContentContainer
          }

          ${
            isMobile
              ? scrollCounter > 2 &&
                styles.homeBeerIPAHiddenOnTheLeftContentContainer
              : scrollCounter > 1 &&
                styles.homeBeerIPAHiddenOnTopContentContainer
          }
          `}
        >
          <div className={styles.homeBeerIPATitle}>LAGER</div>
          {/*<div className={styles.homeBeerIPASubtitle}>*/}
          {/*  Hoppy. Citrus. Smooth.*/}
          {/*</div>*/}
          <div className={styles.homeBeerIPAText}>
            Una cerveza dorada, brillante, equilibrada y deliciosa. De aromas
            florales muy sutiles y un cuerpo medio. Ideal para saborear un trago
            tras otro. Viene directo desde Chicago para traerle una propuesta nueva a los paladares que saben de buena cerveza y quieren disfrutar de una auténtica lager.
          </div>
          <a
            target="_blank"
            href="https://www.tada.com.ar/"
            rel="noreferrer"
            className={styles.homeBeerIPAButton}
            onClick={()=>{
              window.dataLayer.push({
                event: "GAEvent",
                event_category: "Product",
                event_action: "Content",
                event_label: "Comprar",
                interaction: true,
                component_name: "boton_comprar",
                element_text: "Comprar",
              })
            }}
          >
            Comprar ahora
          </a>
        </div>
        <div
          className={`${styles.homeBeerHazyIPAContentContainer} ${
            isMobile
              ? scrollCounter < 4 &&
                styles.homeBeerIPAHiddenOnTheRightContentContainer
              : scrollCounter < 2 &&
                styles.homeBeerIPAHiddenOnTheLeftContentContainer
          }
          ${
            isMobile &&
            scrollCounter === 4 &&
            styles.homeBeerIPAHiddenContentContainer
          }
          `}
        >
          <div className={styles.homeBeerIPATitle}>IPA</div>
          <div className={styles.homeBeerIPASubtitle}>Hoppy. Citrus. Smooth.</div>
          <div className={styles.homeBeerIPAText}>
            473 ml de amargor intenso. Una American IPA de pura cepa, dorada
            como algo muy dorado. Entre los ingredientes tenemos cebada, lúpulo,
            levadura y agua; también personas expertas y apasionadas. Nuestros
            lúpulos son el fuerte, y hasta tienen nombres de superhéroes:
            Pilgrim, Celeia, Cascade y Centennial.
          </div>
          <a
            target="_blank"
            href="https://www.tada.com.ar/"
            rel="noreferrer"
            className={styles.homeBeerIPAButton}
          >
            Comprar ahora
          </a>
        </div>
        <div
          onClick={() => scrollUpFunction()}
          className={styles.homeBeerScrollUpLink}
        >
          <img
            className={styles.homeBeerScrollUpIcon}
            src={scrollUp}
            alt="Scroll"
          />
        </div>
        <div
          onClick={() => scrollDownFunction()}
          className={styles.homeBeerScrollDownLink}
        >
          <img
            className={styles.homeBeerScrollDownIcon}
            src={scrollDown}
            alt="Scroll"
          />
        </div>
      </div>
    </div>
  )
}

export default HomeBeer
