import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import logo from "../../svg/logo-horizontal-white.svg"
import ellipse from "../../svg/ellipse-border.svg"
import scrollUp from "../../svg/scroll-up.svg"
import scrollDown from "../../svg/scroll-down.svg"
import styles from "../../styles/home/home-history.module.scss"

const HomeHistory = ({ isMobile }) => {
  return (
    <div className="section">
      <div className={styles.homeHistoryContainer}>
        <img className={styles.homeHistoryLogo} src={logo} alt="Goose Island" />
        <a
          href="/#beer"
          className={styles.homeHistoryScrollUpLink}
        >
          <img
            className={styles.homeHistoryScrollUpIcon}
            src={scrollUp}
            alt="Scroll"
          />
        </a>
        <a
          href="/#footer"
          className={styles.homeHistoryScrollDownLink}
        >
          <img
            className={styles.homeHistoryScrollDownIcon}
            src={scrollDown}
            alt="Scroll"
          />
        </a>
        <StaticImage
          src="../../images/ellipse-01.png"
          height={1080}
          quality={100}
          formats={["AUTO"]}
          alt="Foco"
          placeholder="none"
          objectFit="contain"
          className={styles.homeHistorySpotlight}
        />
        <div className={styles.homeHistoryTitleAndTextContainer}>
          <div className={styles.homeHistoryTitle}>
            Nacimos en{isMobile ? "\n" : " "}Chicago,{isMobile ? "\n" : " "}pero
            parecemos{"\n"}de otro{" "}
            <span className={styles.homeHistoryHighlightedTitle}>planeta.</span>
            <img
              className={styles.homeHistoryEllipse}
              src={ellipse}
              alt="Óvalo"
            />
          </div>
          <div className={styles.homeHistoryText}>
            La mejor lager de todas. ¿Y sabés qué tiene de distinto a las otras lager? Que es mejor.
          </div>
        </div>
        <div className={styles.homeHistoryImage01Container}>
          <StaticImage
            src="../../images/banner1.jpg"
            height={1080}
            quality={70}
            formats={["AUTO"]}
            alt="Overlay"
            placeholder="none"
            objectFit="cover"
            className={styles.homeHistoryImage01}
          />
        </div>
        <div className={styles.homeHistoryImage02Container}>
          <StaticImage
            src="../../images/banner2.jpg"
            height={1080}
            quality={70}
            formats={["AUTO"]}
            alt="Overlay"
            placeholder="none"
            objectFit="cover"
            className={styles.homeHistoryImage02}
          />
        </div>
        <div className={styles.homeHistoryImage03Container}>
          <StaticImage
            src="../../images/banner3.jpg"
            height={1080}
            quality={70}
            formats={["AUTO"]}
            alt="Overlay"
            placeholder="none"
            objectFit="cover"
            className={styles.homeHistoryImage03}
          />
        </div>
        <div className={styles.homeHistoryImage04Container}>
          <StaticImage
            src="../../images/banner4.jpg"
            height={1080}
            quality={70}
            formats={["AUTO"]}
            alt="Overlay"
            placeholder="none"
            objectFit="cover"
            objectPosition={"0 20%"}
            className={styles.homeHistoryImage04}
          />
        </div>
        <div className={styles.homeHistoryImage05Container}>
          <StaticImage
            src="../../images/banner5.jpg"
            height={1080}
            quality={70}
            formats={["AUTO"]}
            alt="Overlay"
            placeholder="none"
            objectFit="cover"
            objectPosition={"80% center"}
            className={styles.homeHistoryImage05}
          />
        </div>
        <div className={styles.homeHistoryImage06Container}>
          <StaticImage
            src="../../images/banner6.jpg"
            height={1080}
            quality={70}
            formats={["AUTO"]}
            alt="Overlay"
            placeholder="none"
            objectFit="cover"
            className={styles.homeHistoryImage06}
          />
        </div>
        <div className={styles.homeHistoryImage07Container}>
          <StaticImage
            src="../../images/banner7.jpg"
            height={1080}
            quality={70}
            formats={["AUTO"]}
            alt="Overlay"
            placeholder="none"
            objectFit="cover"
            className={styles.homeHistoryImage07}
          />
        </div>
        <div className={styles.homeHistoryImage08Container}>
          <StaticImage
            src="../../images/banner8.jpg"
            height={1080}
            quality={70}
            formats={["AUTO"]}
            alt="Overlay"
            placeholder="none"
            objectFit="cover"
            className={styles.homeHistoryImage08}
          />
        </div>
      </div>
    </div>
  )
}

export default HomeHistory
